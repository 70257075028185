<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <div v-html="formattedActivityText" class=""></div>
    </el-timeline-item>
  </div>
</template>

<script>
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";
export default {
  mixins: [ActivitiesTimelineMixin],
  props: {
    activity: {
      type: Object,
      required: true
    },
    fileImport: {
      type: Object,
      required: false
    }
  },

  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        switch (this.activity.name) {
          case "FILE_IMPORT_SUCCESS":
            this.generateReadableGeneric({
              content: `imported ${this?.activity?.data?.type} file.`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "FILE_IMPORT_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to import file.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
        }
      },
      immediate: true
    }
  }
};
</script>

<style></style>
