
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import FileImportActivitiesTimelineItem from "./FileImportActivitiesTimelineItem.vue";
interface FileImportActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "FileImportActivitiesView",
  components: { FileImportActivitiesTimelineItem },
  mixins: [ActivitiesMixin],

  data(): FileImportActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `FILE_IMPORT_SUCCESS,FILE_IMPORT_FAILURE`.replace(/\s/g, ""),
        activityType: "FileImportActivity"
      };
    }
  }
});
